<template>
  <div>
    <div @click="showSelect = false" :class="tableVisble ? 'overflow' : ''">
      <div class="top">
        <el-select v-model="storeId" placeholder="请选择门店" filterable @click.native="showSelect = false" v-if="userInfo.hierarchy_type_id === 30">
          <template v-for="item in hierList">
            <el-option
              :key="item.hierarchy_id"
              :label="item.hierarchy_name"
              :value="item.hierarchy_id">
            </el-option>
          </template>
        </el-select>
        <div v-if="userInfo.hierarchy_type_id === 40" class="select" style="padding: 0 15px; cursor: auto;">
          {{store.hierarchy_name}}
        </div>
        <div class="select" @click.stop="handleChange">
            <span v-if="currTime.field === 'times'">
              {{currTime.title[0]}}
              <span style="margin: 0 10px;">至</span>
              {{currTime.title[1]}}
            </span>
            <span v-else>{{currTime.title}}</span>
            <i class="el-select__caret el-input__icon el-icon-arrow-down" :class="showSelect? 'up': ''"></i>
            <div class="triangle" v-show="showTriangle"></div>
            <div class="box" @click.stop="" :style="showSelect ? { height: '365px', boxShadow: '2px 5px 5px #ccc' } : {}">
              <div class="list">
                <div class="item" v-for="item in timeList" :key="item.id">
                  <span>{{item.title}}</span>
                  <template v-for="_ in item.arr">
                    <div :key="_.field" :style="_.field === 'last_quarter'? { marginLeft: '17px', } : {}"
                        @click="chooseTime(_)"
                        :class="dropdownCurr.field === _.field? 'active': ''"
                    >
                      {{_.title}}
                    </div>
                  </template>
                </div>
              </div>
              <div class="time">
                <div style="text-indet: 20px;">自定义</div>
                <el-date-picker
                  @change="handlePicker"
                  v-model="times"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
              <div class="bottom">
                <el-checkbox v-model="isDefault">保存为默认值</el-checkbox>
                  <el-button type="primary" plain size="mini" style="width: 60px;" @click="handleTrue">确定</el-button>
              </div>
            </div>
        </div>
      </div>
      <div class="chart-list">
        <div class="block">
          <div class="chart"  ref="chart" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">订单简报</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <!-- <i class="iconfont iconliebiao tag" style="font-size: 20px; color: #909399; vertical-align: middle; cursor: pointer; margin-left: 15px;" ></i> -->
                <i class="iconfont iconshuaxin tag" style="font-size: 20px; color: #909399; vertical-align: middle; cursor: pointer; margin-left: 15px;" @click="userSimpleReport()"></i>
              </div>
            </div>
            <div class="flex-box">
              <div class="wrapper" v-if="loading">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              <div class="ul">
                <div class="item">
                  <div class="num">{{report.order_num}}</div>
                  <div>成交订单数</div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.order_num_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_num_rate }}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_num_rate}}%
                    </span>
                  </div>
                </div>
                <div class="item">
                  <div class="num">
                    {{report.order_amount[0]}}<span style="font-size: 18px; font-weight: 400;">.{{report.order_amount[1]}}</span>
                  </div>
                  <div>订单销售金额</div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.order_amount_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_amount_rate }}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_amount_rate}}%
                    </span>
                  </div>
                </div>
                <div class="item">
                  <div class="num">
                    {{report.order_paid[0]}}<span style="font-size: 18px; font-weight: 400;">.{{report.order_paid[1]}}</span>
                  </div>
                  <div>订单实收金额</div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.order_paid_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_paid_rate }}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.order_paid_rate}}%
                    </span>
                  </div>
                </div>
              </div>
              <div class="ul">
                <div class="item">
                  <div class="num">
                    {{report.sale_weight[0]}}<span style="font-size: 18px; font-weight: 400;">.{{report.sale_weight[1]}}</span>
                  </div>
                  <div>
                    销售克重
                  </div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.sale_weight_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.sale_weight_rate}}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.sale_weight_rate}}%
                    </span>
                  </div>
                </div>
                <div class="item">
                  <div class="num">
                    {{report.recycle_amount[0]}}<span style="font-size: 18px; font-weight: 400;">.{{report.recycle_amount[1]}}</span>
                  </div>
                  <div>回收金额</div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.recycle_amount_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.recycle_amount_rate}}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.recycle_amount_rate}}%
                    </span>
                  </div>
                </div>
                <div class="item">
                  <div class="num">{{report.recycle_num}}</div>
                  <div>回收数量</div>
                  <div style="margin-top: 10px;">
                    环比
                    <span style="color: #67C23A; font-weight: bold;" v-if="Number(report.recycle_num_rate) >= 0">
                      <i class="iconfont iconshangshengshangjiantouxiangshangzhangmianxing" style="margin: 0 3px; font-size: 14px;"></i>{{report.recycle_num_rate}}%
                    </span>
                    <span style="color: #F56C6C; font-weight: bold;" v-else>
                      <i class="iconfont iconbig-up" style="margin: 0 3px; font-size: 14px;"></i>{{report.recycle_num_rate}}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">订单趋势</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <i
                  class="iconfont iconliebiao tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="handleClickChart(1)"
                >
                </i>
                <i
                  class="iconfont iconshuaxin tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="drawChart1"
                >
                </i>
              </div>
            </div>
            <div class="draw">
              <div id="draw1" style="width: 100%; height: 100%; position: relative;">
                <div class="wrapper" v-if="loading1">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart" @click="title = '品类销售占比'" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">品类销售占比</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <i
                  class="iconfont iconliebiao tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="handleClickChart(2)"
                >
                </i>
                <i
                  class="iconfont iconshuaxin tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="drawChart2"
                >
                </i>
              </div>
            </div>
            <div class="draw">
              <div id="draw2" style="width: 100%; height: 100%; position: relative;">
                <div class="wrapper" v-if="loading2">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart" @click="title = '材料成色销售占比'" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">材料成色销售占比</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <i
                  class="iconfont iconliebiao tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="handleClickChart(3)"
                >
                </i>
                <i
                  class="iconfont iconshuaxin tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="drawChart3"
                >
                </i>
              </div>
            </div>
            <div class="draw">
              <div id="draw3" style="width: 100%; height: 100%; position: relative;">
                <div class="wrapper" v-if="loading3">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              </div>
            </div>
          </div>
          <div class="chart" @click="title = '各类优惠金额占比'" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">各类优惠金额占比</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <i
                  class="iconfont iconliebiao tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="handleClickChart(4)"
                >
                </i>
                <i
                  class="iconfont iconshuaxin tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="drawChart4"
                >
                </i>
              </div>
            </div>
            <div class="draw">
              <div id="draw4" style="width: 100%; height: 100%; position: relative;">
                <div class="wrapper" v-if="loading4">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="chart" @click="title = '会员等级'" :style="{height: chartHeight + 'px'}">
            <div class="chart-top">
              <div class="left">会员等级</div>
              <div class="right">
                <el-tag type="info" class="tag">{{store.hierarchy_name}}</el-tag>
                <el-tag type="info" class="tag">
                  <span v-if="currTime.field === 'times'">
                      {{currTime.title[0]}}
                      <span style="margin: 0 10px;">至</span>
                      {{currTime.title[1]}}
                    </span>
                  <span v-else>{{currTime.title}}</span>
                </el-tag>
                <i
                  class="iconfont iconliebiao tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="handleClickChart(5)"
                >
                </i>
                <i
                  class="iconfont iconshuaxin tag"
                  style="font-size: 20px;
                  color: #909399;
                  vertical-align: middle;
                  cursor: pointer;
                  margin-left: 15px;"
                  @click="drawChart5"
                >
                </i>
              </div>
            </div>
            <div class="draw">
              <div id="draw5" style="width: 100%; height: 100%; position: relative;">
                <div class="wrapper" v-if="loading5">
                  <img :src="loadingImg" style="width: 112px; height: 112px;" />
                  <div style="margin-top: 10px; font-size: 18px; color: #999999; width: 112px; text-align: center;">努力加载中...</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <Table @onHide="tableVisble = false" :store="store" :time="currTime"
    :title="title" :url="url" :exportUrl="exportUrl" :rowList1="rowList1"  v-if="tableVisble" :tabs="tabs"
    :url2="url2" :exportUrl2="exportUrl2" :rowList2="rowList2" />
  </div>
</template>

<script>
import { gethierListReq, getBriefReq as userSimpleReportReq, getDefaultReq, setDefultReq, trendReq as userGrowthReq, hotTypeReq as userDistrAndFollowReq, hotMaterialReq as userConvertReq, 
discountReq as userSourceReq, userLevelReq, } from '@/api/chart/index';
import { Area, Pie, Line, DualAxes } from '@antv/g2plot';
import Table from '../Table.vue';
import loadingImg from '@/assets/images/chart/loading.png';
import storage from 'good-storage';

export default {
  data() {
    const userInfo = storage.get('userInfo');
    return {
      rowList1: [],
      rowList2: [],
      url2: '',
      exportUrl2: '',
      userInfo,
      tabs: [],
      exportUrl: '', // 导出接口
      url: '', // 列表接口
      rowList: [],
      title: '',
      report: {
        order_amount: [],
        order_paid: [],
        sale_weight: [],
        recycle_amount: [],
      },
      hierList: [],
      tableVisble: false,
      chart5: null,
      chart4: null,
      chart3: null,
      chart2: null,
      chart1: null, // 第一个图表对象实例
      loading5: false,
      loading4: false,
      loading3: false,
      loading2: false,
      loading1: false, // 图表1加载状态
      loading: true,
      loadingImg,
      chartHeight: 0,
      showTriangle: false, // 展示三角形
      times: [],
      isDefault: false,
      currTime: { title: '', field: '' },
      dropdownCurr: { title: '', field: '' }, // 下拉框当前点击，当点击确定时赋值给currTime
      timeList: [
        { id: 0, title: '天', arr: [{ title: '今天', field: 'today' }, { title: '昨天', field: 'yesterday' }] },
        { id: 1, title: '周', arr: [{ title: '本周', field: 'week' }, { title: '上周', field: 'last_week' }] },
        { id: 2, title: '月', arr: [{ title: '本月', field: 'month' }, { title: '上月', field: 'last_month' }] },
        { id: 3, title: '季', arr: [{ title: '本季度', field: 'this_quarter' }, { title: '上一季度', field: 'last_quarter' }] },
        { id: 4, title: '年', arr: [{ title: '今年', field: 'year' }, { title: '去年', field: 'last_year' }] },
      ],
      showSelect: false,
      storeId: 0,
    };
  },
  components: {
    Table,
  },
  mounted() {
    const chartDom = this.$refs.chart;
    this.chartHeight = chartDom.clientWidth / 2.2;
    this.gethierList();
    this.getDefault();
    if (this.userInfo.hierarchy_type_id === 30) {
      this.hierList = [
        { hierarchy_id: 0, hierarchy_name: '全部门店' },
      ];
    }
    this.$nextTick(() => {
      // this.drawChart1();
      // this.drawChart2();
      // this.drawChart3();
      // this.drawChart4();
      // this.drawChart5();
    });
  },
  watch: {
    showSelect: {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.showTriangle = false;
          }, 200);
        } else {
          this.showTriangle = true;
        }
      },
    },
    storeId: {
      handler() {
        if (this.time && (this.storeId || this.storeId === '')) {
          this.userSimpleReport();
          this.drawChart1();
          this.drawChart2();
          this.drawChart3();
          this.drawChart4();
          // this.drawChart5();
        }
      },
    },
    currTime: {
      handler() {
        if (this.time && (this.storeId || this.storeId === '')) {
          this.userSimpleReport();
          this.drawChart1();
          this.drawChart2();
          this.drawChart3();
          this.drawChart4();
          // this.drawChart5();
        }
      },
    },
  },
  computed: {
    store() {
      let store = this.hierList.filter((item) => item.hierarchy_id === this.storeId)[0];
      if (!store) {
        store = {
          hierarchy_id: '',
          hierarchy_name: '',
        };
      }
      return store;
    },
    time() {
      if (this.currTime.field !== 'times') {
        return this.currTime.field;
      }
      return this.currTime.title;
    },
  },
  methods: {
    handleClickChart(num) {
      this.tableVisble = true;
      if (num === 1) {
        this.title = '订单趋势';
        this.url = '/store/statistical.Order/trendList';
        this.exportUrl = '/store/statistical.Order/trendListExport';
        this.tabs = [];
        this.rowList1 = [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '日期', field_alias: 'day' },
          { field_text: '订单数量', field_alias: 'count' },
          { field_text: '订单金额（元）', field_alias: 'paid_in_amount' },
          { field_text: '门店', field_alias: 'shop_name' },
        ];
        return;
      }
      if (num === 2) {
        this.title = '品类销售占比';
        this.url = '/store/statistical.Order/hotTypeList';
        this.exportUrl = '/store/statistical.Order/hotTypeListExport';
        this.tabs = [];
        this.rowList1 = [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '所属分类', field_alias: 'type_name' },
          { field_text: '订单数', field_alias: 'count' },
          { field_text: '订单金额（元）', field_alias: 'actual_sale_price' },
          { field_text: '门店', field_alias: 'shop_name' },
        ];
        return;
      }
      if (num === 3) {
        this.title = '材料成色销售占比';
        this.url = '/store/statistical.Order/hotMaterialList';
        this.exportUrl = '/store/statistical.Order/hotMaterialListExport';
        this.tabs = [];
        this.rowList1 = [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '材质名称', field_alias: 'material_name' },
          { field_text: '订单数', field_alias: 'count' },
          { field_text: '订单金额（元）', field_alias: 'actual_sale_price' },
          { field_text: '门店', field_alias: 'shop_name' },
        ];
        return;
      }
      if (num === 4) {
        this.title = '各类优惠金额占比';
        this.url = '/store/statistical.Order/discountList';
        this.exportUrl = '/store/statistical.Order/discountListExport';
        this.tabs = [];
        this.rowList1 = [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '优惠类型', field_alias: 'discount_name' },
          { field_text: '优惠金额', field_alias: 'money' },
          { field_text: '门店', field_alias: 'shop_name' },
        ];
        return;
      }
      if (num === 5) {
        this.title = '会员等级';
        this.url = '/store/statistical.User/userLevel';
        this.exportUrl = '/store/statistical.User/userLevelExport';
        this.tabs = [];
        this.rowList1 = [
          { field_text: '序号', field_alias: 'index' },
          { field_text: '门店', field_alias: 'hierarchy_name' },
          { field_text: '会员等级名称', field_alias: 'level_name' },
          { field_text: '人数', field_alias: 'client_user_count' },
          { field_text: '百分比(%)', field_alias: 'percent' },
        ];
      }
    },
    // 设置默认时间
    setDefult() {
      setDefultReq(this.currTime.field)
        .then((res) => {
          if (res.code === 1) {};
        });
    },
    // 获取默认时间
    getDefault() {
      getDefaultReq()
        .then((res) => {
          if (res.code === 1) {
            this.timeList.forEach((item) => {
              item.arr.forEach((_) => {
                if (_.field === res.data.time_type) {
                  this.currTime = _;
                  this.dropdownCurr = _;
                }
              });
            });
          }
        });
    },
    userSimpleReport() {
      this.loading = true;
      userSimpleReportReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.order_amount = res.data.order_amount.split('.');
            res.data.order_paid = res.data.order_paid.split('.');
            res.data.sale_weight = res.data.sale_weight	.split('.');
            res.data.recycle_amount	= res.data.recycle_amount	.split('.');
            this.report = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    gethierList() {
      gethierListReq()
        .then((res) => {
          if (res.code === 1) {
            this.hierList = res.data;
            this.storeId = res.data[0].hierarchy_id;
          }
        });
    },
    // 会员等级: 图表5
    drawChart5() {
      this.loading5 = true;
      userLevelReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading5 = false;
            const { data } = res;
            if (this.chart5) {
              this.chart5.changeData(data);
              return;
            }
            this.chart5 = new Pie('draw5', {
              appendPadding: 10,
              data,
              angleField: 'client_user_count',
              colorField: 'level_name',
              radius: 0.8,
              label: {
                type: 'spider',
                labelHeight: 28,
                content: '{name}\n{percentage}',
              },
              interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            });
            this.chart5.render();
          }
        })
        .catch(() => {
          this.loading5 = false;
        });
    },
    // 品类销售占比: 图表4
    drawChart4() {
      this.loading4 = true;
      userSourceReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading4 = false;
            const { data } = res;
            if (this.chart4) {
              this.chart4.changeData(data);
              return;
            }
            this.chart4 = new Pie('draw4', {
              appendPadding: 10,
              data,
              angleField: 'money',
              colorField: 'discount_name',
              radius: 0.8,
              label: {
                type: 'spider',
                labelHeight: 28,
                content: '{name}\n{percentage}',
              },
              meta: {
                money: {
                  formatter: (v) => `${v}元`,
                },
              },
              legend: {
                itemName: {
                  style: {
                    fontSize: 14,
                  },
                },
              },
              interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            });
            this.chart4.render();
          }
        })
        .catch(() => {
          this.loading4 = false;
        });
    },
    // 材料成色销售占比: 图表3
    drawChart3() {
      this.loading3 = true;
      userConvertReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading3 = false;
            const { data } = res;
            if (this.chart3) {
              this.chart3.changeData(data);
              return;
            }
            this.chart3 = new Pie('draw3', {
              appendPadding: 10,
              data,
              angleField: 'actual_sale_price',
              colorField: 'material_name',
              radius: 0.8,
              label: {
                type: 'spider',
                labelHeight: 28,
                content: '{name}\n{percentage}',
              },
              meta: {
                actual_sale_price: {
                  formatter: (v) => `${v}元`,
                },
              },
              legend: {
                itemName: {
                  style: {
                    fontSize: 14,
                  },
                },
              },
              interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            });
            this.chart3.render();
          }
        })
        .catch(() => {
          this.loading3 = false;
        });
    },
    // 品类销售占比: 图表2
    drawChart2() {
      this.loading2 = true;
      userDistrAndFollowReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading2 = false;
            const { data } = res;
            if (this.chart2) {
              this.chart2.changeData(data);
              return;
            }
            this.chart2 = new Pie('draw2', {
              appendPadding: 10,
              data,
              angleField: 'actual_sale_price',
              colorField: 'type_name',
              radius: 0.8,
              label: {
                type: 'spider',
                labelHeight: 28,
                content: '{name}\n{percentage}',
              },
              meta: {
                actual_sale_price: {
                  formatter: (v) => `${v}元`,
                },
              },
              legend: {
                itemName: {
                  style: {
                    fontSize: 14,
                  },
                },
              },
              interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
            });
            this.chart2.render();
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    // 订单趋势: 图表1
    drawChart1() {
      this.loading1 = true;
      userGrowthReq({
        hierarchy_id: this.storeId,
        time: this.time,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading1 = false;
            const { data } = res;
            if (this.chart1) {
              this.chart1.destroy();
              // return;
            }
            this.chart1 = new DualAxes('draw1', {
              data: [data, data],
              xField: 'day',
              yField: ['count', 'paid_in_amount'],
              xAxis: {
                // range: [0, 1],
                // tickCount: 10,
                // tickInterval: 5,
                tickMethod: 'r-pretty',
              },
              geometryOptions: [
                {
                  geometry: 'line',
                  color: '#5B8FF9',
                },
                {
                  geometry: 'line',
                  color: '#5AD8A6',
                },
              ],
              meta: {
                count: {
                  alias: '订单数量',
                  // formatter: (v) => `${v}件`,
                },
                paid_in_amount: {
                  alias: '订单金额',
                  formatter: (v) => `${v}元`,
                },
              },
            });
            this.chart1.render();
          }
        })
        .catch(() => {
          this.loading1 = false;
        });
    },
    handlePicker() {
      this.dropdownCurr = {
        field: 'times',
        title: this.times,
      };
    },
    handleTrue() {
      this.currTime = this.dropdownCurr;
      this.showSelect = false;
      if (this.isDefault) {
        this.setDefult();
      }
    },
    // 下拉框选择时间
    chooseTime(curr) {
      this.dropdownCurr = curr;
      this.times = [];
    },
    handleChange() {
      this.showSelect = !this.showSelect;
    },
  },
};
</script>

<style lang="less" scoped>
.overflow {
  height: calc(100vh - 90px);
  overflow: hidden;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;
}
.iconfont:hover {
  color: #409EFF !important;
}
.chart-list {
  .block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart {
      width: 49.6%;
      background: white;
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      margin-top: 10px;
      &-top {
        display: flex;
        justify-content: space-between;
        line-height: 32px;
        .left {
          font-weight: bold;
          text-indent: 10px;
          font-size: 18px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 17px;
            top: 9px;
            left: 0;
            border-radius: 2px;
            background: #409EFF;
          }
        }
        .right {
          line-height: 32px;
          .tag {
            margin-left: 10px;
          }
        }
      }
      .flex-box {
        margin-top: 15px;
        height: calc(100% - 47px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          height: 48%;
        .item {
            width: 32%;
            background: rgb(248, 251, 254);
            text-align: center;
            height: 100%;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            .num {
              font-size: 32px;
              font-weight: bold;
              margin-bottom: 1%;
            }
          }
        }
      }
      .draw {
        margin-top: 15px;
        height: calc(100% - 47px);
      }
    }
  }
}
.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  position: absolute;
  top: 42px;
  left: 27px;
  z-index: 9;
}
.box {
  background: white;
  position: absolute;
  top: 48px;
  left: -88px;
  height: 0px;
  overflow: hidden;
  transition: all .2s;
  z-index: 999;
}
/deep/ .el-date-editor--daterange.el-input__inner {
  width: 290px;
}
/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  background: none;
}
/deep/ .el-dropdown-menu__item {
  padding: 0;
}
.time {
  padding: 10px 10px;
  background: rgb(240, 240, 240);
  div {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.bottom {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 20px;
  justify-content: space-between;
}
.top {
  display: flex;
  .select {
    color: #606266;
    cursor: pointer;
    background: white;
    padding: 0 10px 0 15px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    margin-left: 10px;
    position: relative;
    .el-icon-arrow-down {
      color: #C0C4CC;
      cursor: pointer;
      margin-left: 10px;
      transform: rotateZ(0deg);
    }
    .up {
      transform: rotateZ(180deg);
    }
  }
}
.list {
  width: 260px; padding: 10px 15px 10px 15px; box-sizing: border-box; font-size: 12px;
  .item {
    display: flex;
    line-height: 23px;
    .active {
      background: #409EFF;
      color: white !important;
    }
    span {
      padding: 5px 10px; color: #909399; cursor: pointer;
    }
    div {
      padding: 5px 10px; cursor: pointer;
      margin-left: 30px;
    }
    div:hover {
      color: #409EFF;
    }
  }
}
</style>
